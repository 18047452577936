import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import tw, { styled } from 'twin.macro';

import BackgroundImage from 'gatsby-background-image';

const BackgroundCoverStyled = styled(BackgroundImage)`
  ${tw`bg-cover w-screen h-screen`}
`;

const BackgroundCover = ({ children }) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "background.jpg" }) {
        sharp: childImageSharp {
          fluid(
            quality: 85
            maxWidth: 1920
            duotone: { highlight: "#451584", shadow: "#451584", opacity: 30 }
          ) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <BackgroundCoverStyled
      alt={'Background image, hand holding a smartphone'}
      tags={'background'}
      fluid={image.sharp.fluid}
      fadeIn={'soft'}
      backgroundColor={'#181c39'}
    >
      {children}
    </BackgroundCoverStyled>
  );
};

export default BackgroundCover;
