/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import BackgroundCover from './BackgroundCover';
import tw, { styled, css } from 'twin.macro';

const LayoutStyle = styled.div`
  ${tw`flex h-screen w-screen`}
`;

const Layout = ({ children }) => {
  return (
    <>
      <BackgroundCover>
        <LayoutStyle>
          <main
            css={css`
              ${tw`w-screen flex flex-col flex-wrap items-center justify-center `}
            `}
          >
            {children}
          </main>
        </LayoutStyle>
      </BackgroundCover>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
